var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "LBL0001165",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.grid.data,
          merge: _vm.grid.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props }) {
              return [
                props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                  ? _vm._l(
                      _vm.$comm.ibmTagItems(props.row),
                      function (item, index) {
                        return _c(
                          "q-chip",
                          {
                            key: index,
                            staticStyle: { "margin-bottom": "4px !important" },
                            attrs: {
                              color: item.color,
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.linkClick(item, props.row)
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(item.title) + " "),
                            _c("c-assess-tooltip", {
                              attrs: {
                                ibmTooltip: item.ibmTooltip,
                                ibmClassCd: item.ibmClassCd,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    )
                  : void 0,
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }